import React from 'react'


function CheckList({id}) {
  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content" style={{background:'none', border:'none'}}>
              {/* <div className='check-container' id={`${id}Label`}> */}
                <div className='check'>
                  <div className='checklist' style={{border:'0', borderBottom:'2px solid black'}}>
                    <p style={{fontFamily:'Arial black', fontSize:'15px', color:'gray'}}>Demographic:</p>
                    <div>
                      <div className='details'>
                        <p>Patient Id:</p>
                        <span>REG001</span>
                      </div>
                      <div className='details'>
                        <p>DOB:</p>
                        <span>May, 20 1998</span>
                      </div>
                    </div>
                    <div>
                      <div className='details'>
                        <p>Name:</p>
                        <span>Alice Smith</span>
                      </div>
                      <div className='details'>
                        <p>Order:</p>
                        <span>CT Scan</span>
                      </div>
                    </div>
                  </div>
                  <ul className='checker'>
                    <li>
                      <input type='checkbox'/>List of Medication:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Attire:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Allergy:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Jwellery, glasses and Metal Objects:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Drink a constrant water:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Empty Bladder Prior:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Some Maneuver:
                      <input type='text'/>
                    </li>
                    <li>
                      <input type='checkbox'/>Pain:
                      <input type='text'/>
                    </li>
                  </ul>
                  <div style={{marginTop:'50px', marginLeft:'450px', display:'flex', flexDirection:'row',marginRight:'100px'}}>
                      <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal">
                          Close
                      </button>
                      <button type="button" className="btn btn-primary" style={{width:'max-content'}}>
                          Save changes
                      </button>
                  </div>
                </div>
              {/* </div> */}
            </div>
        </div>
    </div>
  )
} 
export default CheckList