import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Components/Pages/Dashboard';
import SideBar from './Components/Designs/SideBar';
import Header from './Components/Designs/Header';
import Footer from './Components/Designs/Footer';
import DashCenter from './Components/DashCenter';
import Bars from './Components/Designs/Bars';
import Home from './Components/Pages/Home';
import Calendar from './Components/Designs/Calendar';
import Patient from './Components/Pages/Patient'
import CalendarPopUp from './Components/Designs/CalendarPopUp';
import BookedPatient from './Components/Designs/BookedPatient';
import RoomPatientList from './Components/Pages/RoomPatientList';
import Vitals from './Components/Designs/Vitals';
import CheckList from './Components/Designs/CheckList';
import Portal from './Components/Portal';
import DoctorList from './Components/Pages/DoctorList';
import CheckOut from './Components/Pages/CheckOut';
import AccountSetting from './Components/Pages/AccountSetting';
import FaceChart from './Components/Pages/FaceChart';
import MyChart from './Components/Pages/MyChart';
import ReportUpload from './Components/Pages/ReportUpload';
import Login from './Components/Pages/Login';
import Referral from './Components/Designs/Referral';
import VitalList from './Components/Designs/VitalList';
function App() {
  return (
  //   <div>
  //    <BrowserRouter>
  //     <Routes>
  //     <Route index element={<Home/>}/>
  //     <Route path='/' element={<Bars/>}>
  //       <Route element={<DashCenter/>}/>
  //       <Route path='/patient' element={<Patient/>}/>
  //     </Route>
  //     </Routes>
  //   </BrowserRouter>
  //  </div>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/home' element={<Dashboard/>}/>
            <Route path='/patient' element={<Patient/>}/>
            <Route path='/rooma' element={<RoomPatientList/>}/>
            <Route path='/doctor' element={<DoctorList/>}/>
            <Route path='/checkouts' element={<CheckOut/>}/>
            <Route path='/accountsetting' element={<AccountSetting/>}/>
            <Route path='/facechart' element={<FaceChart/>}/> 
          </Routes>
        </BrowserRouter>
      // <Login/>
      // <Referral/>
      // <ReportUpload/>
      // <MyChart/>
      // <Dashboard/>
  // <CalendarPopUp/>
      // <DashCenter/>
      // <BookedPatient/>
  );
}

export default App;
