import React, { useState, useEffect } from 'react';
import Calendar from './Designs/Calendar';
import axios from 'axios';

export default function DashCenter() {
  const [patient, setPatient] = useState([]);
  const [appoint,setAppoint]=useState(false)
  useEffect(() => {
    axios.get('https://fakestoreapi.com/products')
      .then((response) => {
        setPatient(response.data);
      })
      .catch((err) => {
        console.log('Something went wrong:', err);
      });
  }, []);
  const handleAppointments=()=>{
    setAppoint(!appoint)
  }

  return (
    <div className="container-fluid">
      <div className="row h-100" style={{ overflow: 'hidden'}}>
        <div className="col-lg-9 p-3">
          <div className="row mb-3">
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="iq-card">
                <div className="iq-card-body iq-bg-primary rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="rounded-circle iq-card-icon bg-primary">
                      <i className="ri-user-fill" />
                    </div>
                    <div className="text-end">
                      <h2 className="mb-0">
                        <span className="counter">5600</span>
                      </h2>
                      <h5 className="">Doctors</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="iq-card">
                <div className="iq-card-body iq-bg-warning rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="rounded-circle iq-card-icon bg-warning">
                      <i className="ri-women-fill" />
                    </div>
                    <div className="text-end">
                      <h2 className="mb-0">
                        <span className="counter">3450</span>
                      </h2>
                      <h5 className="">Nurses</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="iq-card">
                <div className="iq-card-body iq-bg-danger rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="rounded-circle iq-card-icon bg-danger">
                      <i className="ri-group-fill" />
                    </div>
                    <div className="text-end">
                      <h2 className="mb-0">
                        <span className="counter">3500</span>
                      </h2>
                      <h5 className="">Patients</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <Calendar />
          </div>
        </div>
        <div className="col-lg-3 iq-card p-3" style={{ height: '100vh', overflowY: 'auto', scrollbarWidth:'none' , marginTop:'1%', width:'025%', fontSize:'10px'}}>
          <div className="p-3" style={{display:'flex', flexDirection:'row', borderBottom:'1px solid #D3D3D3'}}>
            <p style={{marginTop:'5px', fontSize:'12px'}}>Upcoming Appointment</p>
            <button onClick={handleAppointments} style={{marginLeft:'30%', borderRadius:'4px', backgroundColor:'#00BFFF', border:"none", height:'30px'}}>Today</button>{
              (appoint==true)&&<ul>
              <li>Tomorrow</li>
              <li>Prev Date</li>
            </ul>
            }
          </div>
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 100px)' ,padding: '0 15px', overflow:'scroll', scrollbarWidth:'none', width:'100%', overflowX:'hidden'}}>
            {/* {patient.map((item) => (
              <table className="table">
                <tbody style={{borderBottom:'1px solid gray'}}>
                  <tr key={item.id} >
                    <div style={{display:'flex', flexDirection:'row'}}>
                      <td>PatientName: {item.title}</td>
                      <td>Order: {item.category}</td>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', marginLeft:'8px'}}>
                      <td>Referral: {item.price}</td>
                      <td>hospital: </td>
                    </div>
                  </tr>
                </tbody>
              </table>
            ))} */}
            <table>
              <tr style={{display:'flex', flexWrap:'wrap', color:'black', borderBottom:'1px solid #ADD8E6', width:'250px'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginRight:'20px', marginBottom:'5px'}}>Aelis Patrick</td>
                  <td style={{margin:'10px', marginRight:'20px', marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px', marginRight:'20px', marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginRight:'20px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
              <tr style={{display:'flex', flexDirection:'row', color:'black', borderBottom:'1px solid #ADD8E6'}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Andrew Tate</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
              <tr style={{display:'flex', flexWrap:'wrap', color:'black', borderBottom:'1px solid #ADD8E6'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Banjamin Watchel</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
              <tr style={{display:'flex', flexWrap:'wrap', color:'black', borderBottom:'1px solid #ADD8E6'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Witcher</td>
                  <td style={{margin:'10px',marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
              <tr style={{display:'flex', flexWrap:'wrap', color:'black', borderBottom:'1px solid #ADD8E6'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Jack Smith</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px',marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
              <tr style={{display:'flex', flexWrap:'wrap', color:'black', borderBottom:'1px solid #ADD8E6'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Simmon</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>UltraSound</td>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'50%'}}>
                  <td style={{margin:'10px', marginBottom:'5px'}}>10:00am</td>
                  <td style={{margin:'10px', marginBottom:'5px'}}>Swiss Bierd</td>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
