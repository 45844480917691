import React from 'react'
import SideBar from '../Designs/SideBar'
import Header from '../Designs/Header'
import Footer from '../Designs/Footer'
import DashCenter from '../DashCenter'
import LazyLoad from 'react-lazy-load'


export default function Dashboard() {
  return (
  <>
  
    

    <div className="wrapper">
      {/* Sidebar  */}
      <SideBar/>
      {/* Page Content  */}
      <div id="content-page" className="content-page">
        {/* TOP Nav Bar */}
        {/* TOP Nav Bar END */}
        <Header/>
        <div>
          <DashCenter/>
        </div>
        <Footer/>
      </div>
    </div>
  </>

  )
}
