import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddPatient from '../Designs/AddPatient';
import SideBar from '../Designs/SideBar';
import Footer from '../Designs/Footer';
import Header from '../Designs/Header';

function Patient() {
    const [data, setData] = useState([]);
    const [addition,setAddition]=useState(false)
    const [editValues, setEditValues] = useState({
        resourceType: '',
        identifier: '',
        active: '',
        name: [
            {
                given:'',
                family: ''
            }
        ],
        telecom: [
            {
                system: 'email',
                value: ''
            },
            {
                system: 'phone',
                value: ''
            }
        ],
        gender:'',
        birthDate: '',
        address: [
            {
                text: ''
            }
        ],
         extension:[],
            
    });
    const [currentEditIndex, setCurrentEditIndex] = useState(null);

    useEffect(() => {
        axios.get('https://happ.tactionclients.com/api/getpatient')
            .then((response) => {
                setData(response.data.data);
                console.log(response.data.data);
            })
            .catch((err) => {
                console.log('Something went wrong:', err);
            });
    }, []);

    const handleEditClick = (index) => {
        setCurrentEditIndex(index);
        console.log(`index of values: ${index}`)
        const patient = data[index];
        console.log(`patient id is : ${patient}`);       
        // Accessing the `given` and `family` properties from the `name` array
        const firstName = patient.name[0].given;
        const lastName = patient.name[0].family;
    
        setEditValues({
            name: `${firstName} ${lastName}`, // Combine first and last name
            dob: patient.birthDate,
            email: patient.telecom[0]?.value || '', // Get the first telecom value or empty string
            contactNumber: patient.telecom[1]?.value || '', // Get the second telecom value or empty string
            address: patient.address[0]?.text || '' // Get the first address text or empty string
        });
    };
         
    const handleSaveChanges = () => {
        const pId = data[currentEditIndex].patientId;
        console.log(editValues)
        // Split the name back into given and family for the API request
        const [givenName, ...familyName] = editValues.name.split(' ');
    
        const updatedPatient = {
            resourceType: 'Patient',
            identifier: data[currentEditIndex].identifier,
            active: data[currentEditIndex].active,
            name: [
                {
                    use:'Official',
                    given: [givenName],
                    family: familyName.join(' ')
                }
            ],
            telecom: [
                {
                    system: 'email',
                    value: editValues.email || '',
                    use:'home'
                },
                {
                    system: 'phone',
                    value: editValues.contactNumber || '',
                    use:'home'
                }
            ],
            gender:data[currentEditIndex].gender,
            birthDate: editValues.dob,
            address: [
                {
                    use:'home',
                    line:[
                        '',
                        '',
                    ],
                    city:'',
                    state: "",
                    postalCode: "",
                    country: ""
                }
            ],
            extension: data[currentEditIndex].extension || [], 
        };
        console.log(updatedPatient)
        console.log(`patientId:  ${pId}`)
        axios.put(`https://happ.tactionclients.com/api/updatePatient/${pId}`, updatedPatient)
            .then(response => {
                console.log('User updated:', response.data);
                const updatedData = data.map(item => item.patientId === pId ? { ...item, ...response.data } : item);
                setData(updatedData);
                setCurrentEditIndex(null);
            })
            .catch(error => {
                console.error('Error during update:', error);
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Error setting up request:', error.message);
                }
            });
    };
    const handleAddition=()=>{
        setAddition(!addition)
        console.log(addition)
    }

    const handleInputChange = (field, value) => {
        setEditValues(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

    // const handleSaveChanges = () => {
    //     const pId = data[currentEditIndex].patientId;
    //     console.log(pId)
    //     console.log(editValues)
    //     axios.put(`https://happ.tactionclients.com/api/updatePatient/${pId}`, editValues)
    // .then(response => {
    //     console.log('User updated:', response.data);
    //     const updatedData = data.map((item, i) => i === pId? { ...item, ...response.data } : item);
    //     setData(updatedData);
    //     setCurrentEditIndex(null);
    // })
    // .catch(error => {
    //     console.error('Error details:');
    //     console.error('Message:', error.message);
    //     console.error('Code:', error.code);
    //     console.error('Config:', error.config);
    //     if (error.response) {
    //         console.error('Response data:', error.response.data);
    //         console.error('Response status:', error.response.status);
    //         console.error('Response headers:', error.response.headers);
    //     } else if (error.request) {
    //         console.error('No response received:', error.request);
    //     } else {
    //         console.error('Error setting up request:', error.message);
    //     }
    // });
    // setCurrentEditIndex(null)
    // };
    
    const handleDelete = (id) => {
        const patientId = data[id].patientId;
        console.log(id)
        console.log(patientId)
        axios.delete(`https://happ.tactionclients.com/api/deletePatient/${patientId}`)
        .then(response => {
            console.log('User deleted:', response.data);
            const updatedData = data.filter((_, i) => i !== id);
            setData(updatedData);
        })
        .catch(error => {
            console.error('There was an error deleting the user!', error);
        });
    };

    return (
        <div className="wrapper">
            <SideBar/>
            <div id="content-page" className="content-page">
                <Header/>
                <div>
                    <div className='list'>
                        <div className='btn'>
                            <div className='search'>    
                                <input type='text' placeholder='Search here' />
                                <i className="bi bi-search"></i>
                            </div>
                            <div className='patbtn'>
                                <button className="add-patient-button"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addpatient"
                                    onClose={handleAddition}
                                    >
                                    <i className="bi bi-person-plus"></i> Add Patient
                                </button>
                                <div className="modal fade" id='addpatient' tabIndex="-1" aria-labelledby={`editpatient Label`} aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content" style={{background:'none', border:'none'}}>
                                           <AddPatient/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table className='PatientList' style={{color:'black'}}>
                            <thead>
                                <tr className='headers'>
                                    <th>Patient Id</th>
                                    <th>Patient Name</th>
                                    <th>DOB</th>
                                    <th>Email</th>
                                    <th>Contact Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr className='Values' key={index}>
                                        <td>{index+1}</td>
                                        {/* {`/facechart/${item.patientId}`} */}
                                        <td><Link to='/facechart'>{item.name[0].given} {item.name[0].family}</Link></td>
                                        <td>{item.birthDate}</td>
                                        <td>{item.telecom[0].value}</td>
                                        <td>{item.telecom[1].value}</td>
                                        <td style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                            <div>
                                                <button className="bi bi-pencil-square"
                                                    style={{background:'none', border:'none'}}
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editpatient"
                                                    onClick={() => handleEditClick(index)}>
                                                </button>
                                                <div className="modal fade" id='editpatient' tabIndex="-1" aria-labelledby={`editpatient Label`} aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content" style={{background:'none', border:'none'}}>
                                                            <div className='addingpatient'>
                                                                <h1>Edit Patient</h1>
                                                                <table className='patientstable'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Patient Name:</td>
                                                                            <td><input type='text' value={editValues.name} onChange={(e) => handleInputChange('name', e.target.value)} style={{marginLeft:'20px'}}/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>DOB:</td> 
                                                                            <td><input type='text' value={editValues.dob} onChange={(e) => handleInputChange('dob', e.target.value)} style={{marginLeft:'20px'}} /></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Email:</td>
                                                                            <td><input type='text' value={editValues.email} onChange={(e) => handleInputChange('email', e.target.value)} style={{marginLeft:'20px'}}/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Contact Number:</td>
                                                                            <td><input type='text' value={editValues.contactNumber} onChange={(e) => handleInputChange('contactNumber', e.target.value)}  style={{marginLeft:'20px'}}/></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div style={{display:'flex', flexDirection:'row', marginTop:'100px', marginLeft:'60%'}}>
                                                                    <button style={{border:'none', borderRadius:'8px', backgroundColor:'#808080'}} type="button"
                                                                        className="btn btn-secondary"
                                                                        data-bs-dismiss="modal">Close</button>
                                                                    <button style={{border:'none', backgroundColor:'#1E90FF', color:'white', marginLeft:'10px', borderRadius:'8px'}}
                                                                        onClick={()=>handleSaveChanges()}>Save Change</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button style={{background:'none', border:'none'}} onClick={() => handleDelete(index)}><i className="bi bi-trash3"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default Patient;
