import ReactDOM from 'react-dom';

function Portal({ children }) {
    return ReactDOM.createPortal(
        children,
        document.getElementById('portal-root') // Ensure you have a div with this ID in your HTML
    );
}

export default Portal;
