import React from 'react'

export default function Referral({onClose}) {
  return (
    <div style={{position:'fixed',position: 'fixed',top: 0, left: 0,right: 0,bottom: 0,backgroundColor: 'rgba(0,0,0,0.5)', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'20px', borderRadius:'8px',  height:'400px', width:'500px', backgroundColor:"white"}}>
            <div style={{fontSize:'10px', borderBottom:"2px solid black", backgroundColor:'#48D1CC', padding:'0 40px', marginTop:'20px'}}>
                <h1>Add Referral Provider</h1>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                <div style={{marginTop:'20px'}}>
                    <label>Referral Provider:</label>
                    <input type='text' style={{marginLeft:'50px'}}/>
                </div>
                <div style={{marginTop:'20px'}}>
                    <label>Specialist:</label>
                    <input type='text' style={{marginLeft:'50px'}}/>
                </div>
                <div style={{marginTop:'20px'}}>
                    <label>Email:</label>
                    <input type='text' style={{marginLeft:'50px'}}/>
                </div>
                <div style={{marginTop:'20px'}}>
                    <label>Description:</label>
                    <input type='text' style={{marginLeft:'50px'}}/>
                </div>
                <div style={{marginTop:'50px'}}>
                    <button style={{height:'30px', width:'max-content', border:'none', borderRadius:'6px'}} onClick={onClose}>Close</button>
                    <button style={{height:'30px', width:'max-content', border:'none', backgroundColor:'#00BFFF', marginLeft:'30px', border:'none', borderRadius:'6px', color:'white'}}>Save changes</button>
                </div>
            </div>
        </div>
    </div>
  )
}
