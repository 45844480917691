import React from 'react'

function ReportUpload() {
  return (
    <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'500px', height:'600px', border:'2px solid black', borderRadius:'8px',backgroundColor:'white'}}>
            <div style={{paddingTop:'30px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <table style={{color:'black'}}>
                    <tr>
                        <td>Patient Id:</td>
                        <td>Andrew</td>
                    </tr>
                    <tr>
                        <td>Patient Name:</td>
                        <td>23/03/1994</td>
                    </tr>
                    <tr>
                        <td>Referral Provider:</td>
                        <td>clark</td>
                    </tr>
                    <tr>
                        <td>Order Name:</td>
                        <td>Ultrasound</td>
                    </tr>
                    <tr>
                        <td>Appointment Date:</td>
                        <td>03/02/2024</td>
                    </tr>
                    <tr>
                        <td>Appointment time:</td>
                        <td>03:30 pm</td>
                    </tr>
                    <tr>
                        <td>Pre-Test/vitals/ allergy:</td>
                        <td>Done</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>Test Starts</td>
                    </tr>
                </table>
                <div style={{display:'flex', flexDirection:'row',marginTop:'20px'}}>
                    <p>Choose File: </p><div style={{height:'40px',border:'1px solid black', backgroundColor:'white', marginLeft:'20px'}}><input type='text' style={{height:'30px', border:'none'}}/><i class="bi bi-caret-down-fill"></i></div>
                    <button style={{border:'none', color:'white', backgroundColor:'#1E90FF', height:'30px', marginLeft:'20px', borderRadius:'8px'}}>Upload File</button>
                </div>
                <div style={{height:'120px', width:'max-content', border:'2px solid black', display:'flex', flexDirection:'row', padding:'20px', marginTop:'20px'}}>
                    <div style={{width:'max-content',position: 'relative','display': 'inline-block', marginRight:'20px'}}>
                        <img src='https://images.unsplash.com/photo-1584555684040-bad07f46a21f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww' alt='no image found' style={{width:'50px', height:'60px',display: 'block'}}/>
                        <i class="bi bi-x" style={{position:'absolute', top:'0', right:'0',fontSize:"24px"}}></i>
                        <div>
                            {/* Button trigger modal */}
                            <button
                            style={{height:'10px',fontSize:'6px' ,width:'max-content', border:'none',display:'flex', justifyContent:'center'}}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#report1"
                            >
                            View
                            </button>
                            {/* Modal */}
                            <div className="modal fade" id="report1" tabIndex="-1" aria-labelledby="report1Label" aria-hidden="true">
                                <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                    <h5 className="modal-title" id="report1Label">Image</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                    <img src="https://images.unsplash.com/photo-1584555684040-bad07f46a21f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww" style={{width:" 100%", height: "auto", alt:"Image"}}/>"
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'max-content',position: 'relative','display': 'inline-block', marginRight:'20px'}}>
                        <img src='https://plus.unsplash.com/premium_photo-1718294739162-4349254ea011?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTd8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww' alt='no image found' style={{width:'50px', height:'60px',display: 'block'}}/>
                        <i className="bi bi-x" style={{position:'absolute', top:'0', right:'0',fontSize:"24px"}}></i>
                        <div>
                            {/* Button trigger modal */}
                            <button
                            style={{height:'10px',fontSize:'6px' ,width:'max-content', border:'none',display:'flex', justifyContent:'center'}}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#report2"
                            >
                            View
                            </button>
                            {/* Modal */}
                            <div className="modal fade" id="report2" tabIndex="-1" aria-labelledby="report1Label" aria-hidden="true">
                                <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                    <h5 className="modal-title" id="report1Label">Image</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                    <img src="https://plus.unsplash.com/premium_photo-1718294739162-4349254ea011?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTd8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww" style={{width:" 100%", height: "auto", alt:"Image"}}/>"
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'max-content',position: 'relative','display': 'inline-block', marginRight:'20px' }}>
                        <img src='https://images.unsplash.com/photo-1522849696084-818b29dfe210?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww' alt='no image found' style={{width:'50px', height:'60px',display: 'block'}}/>
                        <i class="bi bi-x" style={{position:'absolute', top:'0', right:'0',fontSize:"24px"}}></i>
                        <div>
                            {/* Button trigger modal */}
                            <button
                            style={{height:'10px',fontSize:'6px' ,width:'max-content', border:'none',display:'flex', justifyContent:'center'}}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#report3"
                            >
                            View
                            </button>
                            {/* Modal */}
                            <div className="modal fade" id="report3" tabIndex="-1" aria-labelledby="report1Label" aria-hidden="true">
                                <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                    <h5 className="modal-title" id="report1Label">Image</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                    <img src="https://images.unsplash.com/photo-1522849696084-818b29dfe210?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fHJhZGlvbG9neSUyMHRlc3RzJTIwcmVwb3J0fGVufDB8fDB8fHww" style={{width:" 100%", height: "auto", alt:"Image"}}/>"
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:'20px'}}>
                    <p>Comments/Findings: <input type='text'/></p>
                    <p><input type='checkbox'/>Verify Now</p>
                </div>
            </div>
        </div>
        <div style={{display:'flex',marginLeft:'30%' ,marginTop:'20px'}}>
            <button style={{border:'none', backgroundColor:'#1E90FF', color:'white', borderRadius:'8px'}}>Ready for CheckOut</button>
        </div>
    </div>
  )
}
export default ReportUpload