import React from 'react'
import SideBar from '../Designs/SideBar'
import Header from '../Designs/Header'
import Footer from '../Designs/Footer'
import DashCenter from '../DashCenter'


export default function AccountSetting() {
  return (
  <>
    <div className="wrapper">
      {/* Sidebar  */}
      <SideBar/>
      {/* Page Content  */}
      <div id="content-page" className="content-page">
        {/* TOP Nav Bar */}
        {/* TOP Nav Bar END */}
        <Header/>
        <div style={{height:'630px'}}>
        <h1 style={{ fontSize:'20px', padding:'10px',paddingLeft:'20px', width:'530px'}}>Account Setting</h1>
            <div style={{display:'flex', flexDirection:'row', paddingTop:'20px'}}>
                <div style={{width:'550px', border:'none',  borderRadius:'20px', background:'white', marginLeft:'20px', paddingTop:'50px', paddingBottom:'50px'}}>
                    <table >
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>User Name</td>
                            <td><input type='text' placeholder='username' style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>Email Id</td>
                            <td><input type='text' placeholder='xyz123@gmail.com'style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>Conatct Number</td>
                            <td><input type='text' placeholder='abc321@gmail.com'style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'row', padding:'30px'}}>
                            <button style={{border:'none', backgroundColor:'#00BFFF', borderRadius:'8px', color:'white', marginTop:"5%", marginLeft:'2%'}}>Submit</button>
                            <button style={{border:'none', backgroundColor:'gray', borderRadius:'8px', color:'white', marginTop:"5%", marginLeft:'2%'}}>Cancel</button>
                        </tr>
                    </table>
                </div>
                <div style={{width:'550px', border:'none',  borderRadius:'20px', background:'white', marginLeft:'20px', alignItems:'center', paddingTop:'50px'}}>
                    <table>
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>old Password</td>
                            <td><input type='text' placeholder='username' style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>New Password</td>
                            <td><input type='text' placeholder='username' style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'column', margin:'20px', marginBottom:'0'}}>
                            <td>Confirm Password</td>
                            <td><input type='text' placeholder='username' style={{width:'400px', height:'50px', borderRadius:'8px', border:'1px solid gray'}}/></td>
                        </tr>
                        <tr style={{display:'flex', flexDirection:'row', padding:'30px'}}>
                            <button style={{border:'none', backgroundColor:'#00BFFF', borderRadius:'8px', color:'white', marginTop:"5%", marginLeft:'2%'}}>Submit</button>
                            <button style={{border:'none', backgroundColor:'gray', borderRadius:'8px', color:'white', marginTop:"5%", marginLeft:'2%'}}>Cancel</button>
                        </tr>
                    </table>
                </div>  
            </div>
        </div>
        <Footer/>
        {/* Footer */}

        {/* Footer END */}
      </div>
    </div>
  </>

  )
}
