import React, { useState } from 'react'
import axios from 'axios';
export default function AddPatient({onClose}) {
  const [patientData, setPatientData] = useState({
        resourceType: "",
        identifier: [
          {
              use: "",
              system: "",
              value: ""
          }
      ],
        active: true,
        name: [
          {
              use: "",
              family: '',
              given: [
                  ""
              ]
          }
      ],
        telecom: [
          {
              system: "",
              value: "",
              use: ""
          },
          {
              system: "",
              value: "",
              use: ""
          }
      ],
        gender: "",
        birthDate: "",
        address: [
          {
              use: "",
              line: [
                  "",
                  ""
              ],
              city: "",
              state: "",
              postalCode: "",
              country: ""
          }
      ], 
      extension: [
        {
          url: "",
          valueCode: ""
        }
      ]    
  });
  const [change,setChange]=useState(false)
  const handleInputChange = (e) => {
    const {name,value}=e.target
    if (name === 'family' || name === 'given') {
      setPatientData((prevData) => ({
        ...prevData,
        name: {
          ...prevData.name,
          [name]: name === 'given' ? [value] : value
        }
      }));
    } else {
      setPatientData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }  
  const handleChange=()=>{
      // const fhirMessage=createFhirMessage()
      // console.log(`my message: ${fhirMessage}`)
      console.log(`my patient name is: ${patientData.patientName}`)
      const [givenName, ...familyName] = patientData.patientName.split(' ');
        const fhirMessage = {
          resourceType:'Patient', 
          // patientData.resourceType,
          identifier: [{ use:'Official', system: 'http://hl7.org/fhir/sid/us-ssn', value: 'ABC123' }],
          active: patientData.active|| '',
          name: [{ use: 'Official'|| '', family: familyName.join(' ')|| '', given: [givenName] || ['']}],
          telecom: [
            {
            system:'email',
            value:patientData.email|| '',
            use:'home'
          },
            { 
              system:'phone',
              value:patientData.contactNumber,
              use:'home'
            }],
          gender: patientData.gender.toLowerCase()|| '',
          birthDate: patientData.birthDate|| '',
          address: [
            {
            use: 'home',
            line: [patientData.address.line|| '', 
                  ''],
            city: patientData.address.city|| '',
            state: patientData.address.state|| '',
            postalCode: patientData.address.postalCode|| '',
            country:' United State'
          }
        ],
          extension: [{ url: patientData.extension.url|| '', valueCode: patientData.extension.valueCode||'' }]
        };
      setChange(!change)
      console.log(patientData)
      axios.post('https://happ.tactionclients.com/api/postcreate', fhirMessage)
      .then(response=>{
        alert('Patient added successfully')
        // setRes(response.data)
      })
      .catch((err)=>{
        console.log('something went wrong')
      })
  }
  
  return (
    <>
      <div style={{display: 'flex',
      flexDirection:'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor:' rgba(0,0,0,0.5)'}}>
        <div style={{display: 'flex', flexDirection:'column',background:'white',height:'700px', width:'500px', padding:'2%'}}>
          <h1 style={{fontSize:'20px', borderBottom:'2px solid black', padding:'0 20px', fontFamily:'serif', backgroundColor:'#00CED1'}}>Add patient</h1>
          <table style={{display:'flex', flexDirection:'column', alignItems:'flex-end', marginTop:'5%', marginRight:'20%'}}>
            <tr style={{marginTop:'20px'}}>
              <td>Patient name:</td>
              <td><input type="text" name="patientName" value={patientData.patientName} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>DOB:</td> 
              <td><input type='text' name='birthDate' value={patientData.birthDate} onChange={handleInputChange}  style={{marginLeft:'20px'}} /></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>Email:</td>
              <td><input type='text' name='email' value={patientData.email} onChange={handleInputChange}  style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>Gender:</td>
              <td><input type='text' name='gender' value={patientData.gender} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>Contact Number:</td>
              <td><input type='text' name='contactNumber' value={patientData.contactNumber} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>Address:</td>
              <td><input type='text' name='address' value={patientData.address.line} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>State:</td>
              <td><input type='text' name='state' value={patientData.address.state} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr> 
            <tr style={{marginTop:'20px'}}>
              <td>City:</td>
              <td><input type='text' name='city' value={patientData.address.city} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
            <tr style={{marginTop:'20px'}}>
              <td>Postal Code:</td>
              <td><input type='text' name='postalcode' value={patientData.address.postalCode} onChange={handleInputChange} style={{marginLeft:'20px'}}/></td>
            </tr>
          </table>
            {/* <div style={{display:'flex', flexDirection:'row', marginTop:'20%', marginLeft:'60%'}}>
              <button style={{border:'none', borderRadius:'8px', backgroundColor:'#DCDCDC', height:"30px"}} onClick={onClose}>Close</button>
              <button style={{border:'none', backgroundColor:'#1E90FF', color:'white', marginLeft:'10px', borderRadius:'8px'}} onClick={handleChange}>Save Change</button>
            </div> */}
            <div className="modal-footer" style={{border:'none', marginTop:'20%'}}>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary"  onClick={handleChange}>
                        Save changes
                    </button>
                </div>
        </div>
      </div>
    </>
  )
}
