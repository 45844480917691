import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default calendar styles
import FullCalendar from '@fullcalendar/react'; // Core FullCalendar package
import dayGridPlugin from '@fullcalendar/daygrid'; // Day grid view
import timeGridPlugin from '@fullcalendar/timegrid'; // Time grid view
import listPlugin from '@fullcalendar/list'; // List view
import interactionPlugin from '@fullcalendar/interaction';
import AppointmentModal from './AppointmentModal';
import {Modal, Button} from 'react-bootstrap';

function CalendarPopUp({show,handleClose}) {
  const [date, setDate] = useState(new Date());

  const handleOnChange = (newDate) => {
    setDate(newDate);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDate(null);
  };
  const events = [
    { title: 'All Day Event', start: '2019-12-01', color: '#fc9919' },
    { title: 'Long Event', start: '2019-12-07', end: '2019-12-10', color: '#ffc107' },
    { groupId: '999', title: 'Repeating Event', start: '2019-12-09T16:00:00', color: '#17a2b8' },
    { groupId: '999', title: 'Repeating Event', start: '2019-12-16T16:00:00', color: '#17a2b8' },
    { title: 'Conference', start: '2019-12-11', end: '2019-12-13', color: '#27e3f4' },
    { title: 'Meeting', start: '2019-12-12T10:30:00', end: '2019-12-12T12:30:00', color: '#089bab' },
    { title: 'Lunch', start: '2019-12-12T12:00:00', color: '#777D74' },
    { title: 'Meeting', start: '2019-12-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2019-12-28T07:00:00', color: '#28a745' },
    { title: 'Meeting', start: '2020-01-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2020-01-02T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2020-01-25' },
    { title: 'Birthday Party', start: '2020-01-13T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2019-12-28' },
    { title: 'Meeting', start: '2020-01-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2020-01-13T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2020-01-28' },
    { title: 'All Day Event', start: '2020-02-01', color: '#fc9919' },
    { title: 'Long Event', start: '2020-02-07', end: '2020-02-10', color: '#ffc107' },
    { groupId: '999', title: 'Repeating Event', start: '2020-02-09T16:00:00', color: '#17a2b8' },
    { groupId: '999', title: 'Repeating Event', start: '2020-02-16T16:00:00', color: '#17a2b8' }
  ];
  if (!show) {
    return null;
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
    }}>
      <div style={{display: 'flex', flexDirection:'column',background:'white',height:'fit-content', width:'fit-content', padding:'2%'}}>
        <h1>Appointment Schedule Calendar</h1>
        <div style={{display: 'flex', flexDirection:'row'}}>
            <div style={{
                height: 'fit-content',
                width: '350px',
                backgroundColor: '#fff',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop:'3.5%'
            }}>
                <Calendar 
                onChange={handleOnChange} 
                value={date} 
                />
            </div>
            <div className="calendar-container" style={{ marginTop: '30px', backgroundColor:'white',marginLeft:'2%',boxShadow: '0 4px 8px rgba(0,0,0,0.2)', padding:'20px' }}>
              <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                  }}
                  events={events}
                  dateClick={handleDateClick}
              />
            </div>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
            <button onClick={handleClose} style={{borderRadius:'6px', backgroundColor:'gray', marginTop:'10px', width:'50px',border:'none'}}>Close</button>
            <button onClick={handleClose} style={{borderRadius:'6px', backgroundColor:'#00BFFF', marginTop:'10px', width:'80px', border:'none', marginLeft:'2%'}}>Schedule</button>
        </div>
      </div>
    </div>
  );
}

export default CalendarPopUp;
