// src/Login.js
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Dashboard from './Dashboard';
import { useNavigate } from 'react-router-dom';
import { preventDefault } from '@fullcalendar/core/internal';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
    const [error, setError]=useState('')
    const [message, setMessage]=useState([])
    const navigate=useNavigate()
      useEffect(()=>{
        axios.post('https://happ.tactionclients.com/api/login')
      .then((response)=>{
          setMessage(response.data)
          console.log(response.data)
      })
      .catch((err)=>{
        console.log('something went wrong')
      })
      },[])
      const handleLogin=(e)=>{
          e.preventDefault();
          if(username=='Nitin2' && password=='Nitin@321'){
              navigate('/home')
          }
          else{
            setUsername('')
            setPassword('')
          }
      }

  return (
    <div className='login'>
      <div className="login-container" style={{paddingBottom:'50px'}}>
        <form className="login-form ">
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <h2 style={{marginLeft:'20%',fontFamily:' Arial black'}}>Login</h2>
            <div style={{ width:'50px', height:'70px', display:'flex', flexDirection:'row', alignItems:'center', marginLeft:'50%'}}>
              <img src='https://cdn-icons-png.flaticon.com/128/3408/3408455.png' style={{height:'70px', width:'70px'}}/>
            </div>
            <div className="form-group" style={{background:'none'}}>
              <label htmlFor="username" style={{ fontWeight:'bold'}}>Username</label>
              <input
                style={{marginLeft:'20px'}}
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group" style={{background:'none'}}>
              <label htmlFor="password" style={{ fontWeight:'bold'}}>Password</label>
              <input
                style={{marginLeft:'20px'}}
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          <p style={{marginLeft:'40px', marginTop:'20px', color:'#4169E1'}}>Forget Password</p>
          <button type="submit" className="login-button" style={{width:'300px', marginLeft:'16%', marginTop:'10px', display:'flex', alignItems :'center', justifyContent:'center'}} onClick={handleLogin}>Login</button>
          <p style={{marginLeft:'35%', display:'flex', flexDirection:'row'}}>Not a member/<p style={{color:'#4169E1'}}>SignUp now</p></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
