import React from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { Outlet, Link } from "react-router-dom";

export default function Bars() {
  return (
    <div>
        <div className="wrapper" style={{scrollbarWidth:'0'}}>
        <SideBar/>
        <div>
            <Header/>
            <div style={{ overflow: "scroll", scrollbarWidth:'none',height:"90vh"}}>
              {/* <Outlet /> */}
            </div>
        </div>
    </div>
    </div>
  )
}
