import React, { useEffect, useState } from 'react'
import SideBar from '../Designs/SideBar'
import Header from '../Designs/Header'
import Footer from '../Designs/Footer'
import DashCenter from '../DashCenter'
import axios from 'axios'

export default function DoctorList() {
    const [data,setData]=useState([])
    useEffect(()=>{
        axios.get('https://happ.tactionclients.com/api/getAllDoctors')
        .then((response)=>{
            console.log(response.data.data)
            setData(response.data.data)
        })
        .catch((err) => {
            console.log('Something went wrong:', err);
        });
    },[])
  return (
  <>
    <div className="wrapper">
      {/* Sidebar  */}
      <SideBar/>
      {/* Page Content  */}
      <div id="content-page" className="content-page">
        {/* TOP Nav Bar */}
        {/* TOP Nav Bar END */}
        <Header/>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                <h4 className="card-title">Doctors List</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.map((item,index)=>(
                            <div className="col-sm-6 col-md-3" >
                                <div className="iq-card">
                                    <div className="iq-card-body text-center" key={index}>
                                        <div className="doc-profile">
                                            <img
                                                className="rounded-circle img-fluid avatar-80"
                                                src={item.image_uploaded}
                                                alt="profile"/>
                                        </div>
                                        <div className="iq-doc-info mt-3">
                                            <h5>{item.first_name}{item.last_name}</h5>
                                            <p className="mb-0">{item.specialty[0]},{item.specialty[1]}</p>
                                            <a href="javascript:void();">{item.email}</a>
                                        </div>
                                        <div className="iq-doc-description mt-2">
                                            <p className="mb-0">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                                                auctor non erat non gravida. In id ipsum consequat
                                            </p>
                                        </div>
                                        {/* <div className="iq-doc-social-info mt-3 mb-3">
                                            <ul className="m-0 p-0 list-inline">
                                                <li>
                                                <a href="#">
                                                    <i className="ri-facebook-fill" />
                                                </a>
                                                </li>
                                                <li>
                                                <a href="#">
                                                    <i className="ri-twitter-fill" />
                                                </a>{" "}
                                                </li>
                                                <li>
                                                <a href="#">
                                                    <i className="ri-google-fill" />
                                                </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                        <a href="profile.html" className="btn btn-primary">
                                        View Profile
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
            </div>
        </div>
        <Footer/>
        {/* Footer */}

        {/* Footer END */}
      </div>
    </div>
  </>

  )
}
