import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Portal from '../Portal';
import CalendarPopUp from './CalendarPopUp';
import AddPatient from './AddPatient';
import Referral from './Referral';
const ReferralForm = () => {
  const [patient, setPatient] = useState('');
  const [data,setData]=useState([])
  const [order, setOrder] = useState('');
  const [referralType, setReferralType] = useState('self');
  const [note, setNote] = useState('');
  const [doctor, setDoctor] = useState('inactive');
  const [added, setAdded] = useState('yes');
  const [patientexists, setPatientexists] = useState(false);
  const [test, setTest] = useState('off');
  const [slot, setSlot] = useState(false);
  const [addition, setAddition]=useState(false)
  const [addReferral, setAddreferral]=useState(false)
  useEffect(()=>{
    axios.get('https://happ.tactionclients.com/api/getpatient')
    .then((response)=>{
        setData(response.data.data)
        console.log(response.data.data);
      })
      .catch((err) =>{
          console.log('Something went wrong:', err);
      });
    },[]);
  const handleCalender = () => {
    setSlot(!slot);
    console.log(slot);
  };

  const handleReferralTypeChange = (type) => {
    setReferralType(type);
  };

  const handledrop = () => {
    setDoctor(doctor === 'active' ? 'inactive' : 'active');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ patient, order, referralType, note });
  };

  const handleAddReferral = () => {
    setAddreferral(!addReferral)
  };

  const handlePatient = () => {
    setAdded(added === 'yes' ? 'not' : 'yes');
  };

  const handleTest = () => {
    setTest(test === 'off' ? 'on' : 'off');
  };

  const handleDateSelect = (date) => {
    console.log('Selected date:', date);
    // Update state or form with selected date
  };

  const handleAddition=()=>{
    setAddition(!addition)
    console.log(addition)
  }

  return (
    <div className="referral-form" onSubmit={handleSubmit}>
      <div className="referral-type">
        <button type="button" className={referralType === 'self' ? 'active' : ''} onClick={() => handleReferralTypeChange('self')} >Self Referral</button>
        <button type="button" className={referralType === 'provider' ? 'active' : ''} onClick={() => handleReferralTypeChange('provider')}>Referral Provider</button>
      </div>
      <div className='time'>
        <h4>11:00 am 20th June 2024</h4>
        <p>-Until 11:30am</p>
      </div>
      <div className="form-group" style={{display:'flex',alignContent:'center',flexDirection:'row'}} onClick={handlePatient}>
        <img src='https://cdn-icons-png.flaticon.com/128/2179/2179285.png' alt="Patient icon"/>
        <label >No Patient Selected</label>
      </div>
      {added === 'not' && (
        <div className='addpatient'>
          <div className='click-patient'>
            <p style={{ fontFamily: 'Impact, Haettenschweiler, Arial Narrow Bold, sans-serif', color: 'black' }}>Search by Name</p>
            <input type="text" value={patient} onChange={(e) => setPatient(e.target.value)} placeholder="Please Click Here to Search" />
            <button onClick={handleAddition} style={{background:'none', border:'none'}}>
              <img src='https://cdn-icons-png.flaticon.com/128/863/863864.png' alt="Search icon" />
            </button>
            {/* <div>
              <button 
                style={{border:"none", background:'none'}}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addpatient">
                  <img src='https://cdn-icons-png.flaticon.com/128/863/863864.png'/>
              </button>
              <AddPatient id="addpatient"/>
            </div> */}
          </div>
          {addition === false && (
            <div style={{ marginLeft: '40%', padding: '10px 10px', fontSize: '10px', color: 'rgb(231, 227, 227)' }}>No search Result found</div>
          )}
        </div>
      )}
      <div className="form-group" style={{display:'flex',alignContent:'center',flexDirection:'row'}} onClick={handleTest}>
        <img src='https://cdn-icons-png.flaticon.com/128/3222/3222662.png' alt="Order icon"/>
        <label>Order</label>
      </div>
      {test === 'on' && (
        <div className='addpatient'>
          <div className='click-patient' style={{ border: 'none' }}>
            <h5>Test Name or<p>Test Id</p></h5>
            <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} placeholder="Please Click to Select" />
            <img src='https://cdn-icons-png.flaticon.com/128/3022/3022254.png' alt="Order icon" />
          </div>
          <button style={{ marginLeft: '70%', marginBottom: '10px', borderRadius: '4px' }} onClick={handleCalender}>Choose New Date</button>
        </div>
      )}
      <div className="referral">
        <img src='https://cdn-icons-png.flaticon.com/128/8815/8815112.png' alt="Referral icon"/>
        <input type="text" className={referralType === '' ? 'active' : ''} onClick={handledrop} placeholder='Referral Provider'/>
        <button onClick={handleAddReferral}>Add Referral</button>
        {doctor === 'active' && (
          <div className='dropdown'>
            <ul>
              <li>ds</li>
              <li>de</li>
              <li>e</li>
              <li>ds</li>
            </ul>
          </div>
        )}
      </div>
      <div className="note">
        <label>Note:</label>
        <div className='withbtn'>
          <textarea value={note} onChange={(e) => setNote(e.target.value)}></textarea>
        </div>
      </div>
      <button type="submit" style={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center'}}>Submit</button>
      {slot && (
        <div>
          <CalendarPopUp show={slot} handleClose={handleCalender} />
        </div>
      )}
      {
        addition &&
          <AddPatient onClose={handleAddition}/>
      }
      { 
        addReferral && 
          <Referral onClose={handleAddReferral}/>
        }
    </div>
  );
};

export default ReferralForm;
