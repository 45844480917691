import React, { useState } from "react";
import TotalRooms from "./TotalRooms";

function BookedPatient({id}){
  const [drop,setDrop]=useState(false)
  const [values,setValues]=useState(false)
  const handleDrop=()=>{
    setDrop(!drop)
    setValues(false)
  }
  const handleValues=()=>{
    setValues(!values)
  }
  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content" style={{background:'none', border:'none'}}>
                <div className="Patient-Ccontainer">
                  <div className="container" style={{width:'1000px', margin:'10%'}}>
                    <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                          <div className="header">
                            <div className="profile">
                              <img src="images/user/11.png" alt="Profile" />
                              <div className="info">
                                <h2>Alana Fuller</h2>
                                <p>January 1st 1980 / 30 years old / Female</p>
                                <p>Contact Number: +1 379 693 1309</p>
                                <p>Email: donotsend@example.com</p>
                                <p>
                                  Address: #320- 638 Broughton Street, Vancouver, British Columbia
                                </p>
                              </div>
                            </div>
                            <div className="actions"> 
                              <button>Start/Open...</button>
                            </div>
                          </div>
                          <div className="main-content">
                            <div className="card">
                              <h3>Status Tags</h3>
                              <div className="status-tags">
                                <div className="tag" style={{backgroundColor:''}}>Order Scheduled</div>
                              </div>
                            </div>
                            <div className="card">
                              <h3>Referral Provider</h3>
                              <div className="provider-info">
                                <div>
                                    <img src="https://cdn-icons-png.flaticon.com/128/2418/2418174.png" />
                                </div>
                                <div>
                                    <h5>Dr. John Smith</h5>
                                    <p>Medical Director</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <h3>Recent Activity</h3>
                              <div className="recent-activity">
                                <div className="activity-item">
                                  <p>Alana Fuller - SEAT001 MRI</p>
                                  <p>15th Oct 2023 10:30 AM - Complete</p>
                                </div>
                                <div className="activity-item">
                                  <p>Alana Fuller - SEAT351 Ultrasound</p>
                                  <p>21st July 2023 01:30 PM - Canceled</p>
                                </div>
                                <div className="activity-item">
                                  <p>Alana Fuller - SEAT151 CT Scan</p>
                                  <p>18th Feb 2023 05:00 PM - Complete</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <h3>Latest Encounter</h3>
                              <div className="latest-encounter">
                                <p>No Encounter Started</p>
                              </div>
                            </div>
                      </div>
                    </div>
                    <div className="modal-footer" style={{display:'flex', flexDirection:'row', alignContent:'center'}}>
                            <button style={{backgroundColor:'#00BFFF',border:'none', color:'white', height:'30px', borderRadius:'4px', }}>Reschedule Appointment</button>
                            <button style={{backgroundColor:'#00BFFF',border:'none', color:'white', height:'30px', borderRadius:'4px'}}>Cancel Appointment</button>
                              <button style={{backgroundColor:'#00BFFF',border:'none', color:'white', height:'30px', borderRadius:'4px'}} onClick={handleDrop}>
                                Check In
                              </button>{
                                (drop&& 
                                  <div style={{display:'flex',height:'max-xontent', width:'max-content',padding:'10px',flexDirection:'column', borderRadius:'8px', position:'absolute',marginBottom:'35%', marginRight:'20%', backgroundColor:"white"}}>
                                        <p style={{fontFamily:'Arial Black'}}>Room Allotment</p>
                                        <div style={{display:'flex', flexDirection:'column',flexWrap:'wrap'}}>
                                            <input type="text" onClick={handleValues}/>{
                                              values&&<ul>
                                              <li>Room A</li>
                                              <li>Room B</li>
                                              <li>Room C</li>
                                              <li>Room D</li>
                                            </ul>
                                            }
                                        </div>
                                    </div>
                                )
                              }
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={()=>setDrop(false)}
                            >
                              Close
                            </button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BookedPatient;
