import React from 'react';
import {Modal, Button} from 'react-bootstrap';
// import Patient from './Patient';

import SlotAvailable from './SlotAvailable'
const AppointmentModal = ({ show, handleClose, selectedDate }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Book Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body >
          <SlotAvailable/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentModal;
