import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // Core FullCalendar package
import dayGridPlugin from '@fullcalendar/daygrid'; // Day grid view
import timeGridPlugin from '@fullcalendar/timegrid'; // Time grid view
import listPlugin from '@fullcalendar/list'; // List view
import interactionPlugin from '@fullcalendar/interaction';
import AppointmentModal from './AppointmentModal';
import Calendar from 'react-calendar';
import axios from 'axios';

export default function CalendarPage() {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date,setDate]=useState(new Date())
  const [data,setData]=useState([])
  const [order,setOrder]=useState([])
  const [inputValue,setInputValue]=useState(false)
  const [name,setName]=useState('')
  const handleChange=(newDate)=>{
    setDate(newDate)
  }
  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr);
    setShowModal(true);
  };
  // const handleInputChange=(nam)=>{
  //   setInputValue(!inputValue)
  //   console.log(nam)
  // }
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDate(null);
  };
  useEffect(()=>{
    axios.get('https://happ.tactionclients.com/api/getmodality')
    .then((response)=>{
      console.log(response.data.data)
      setData(response.data.data)
    })
    .catch((err)=>{
      console.log('Something went wrong !')
    })
  },[])
  useEffect(()=>{
    console.log(name)
    axios.get(`https://happ.tactionclients.com/api/getTests/${name}`)
    .then((response)=>{
      console.log(response.data)
    })
    .catch((err)=>{
      console.log('Something went wrong')
    })
  },[])
  const events = [
    { title: 'All Day Event', start: '2019-12-01', color: '#fc9919' },
    { title: 'Long Event', start: '2019-12-07', end: '2019-12-10', color: '#ffc107' },
    { groupId: '999', title: 'Repeating Event', start: '2019-12-09T16:00:00', color: '#17a2b8' },
    { groupId: '999', title: 'Repeating Event', start: '2019-12-16T16:00:00', color: '#17a2b8' },
    { title: 'Conference', start: '2019-12-11', end: '2019-12-13', color: '#27e3f4' },
    { title: 'Meeting', start: '2019-12-12T10:30:00', end: '2019-12-12T12:30:00', color: '#089bab' },
    { title: 'Lunch', start: '2019-12-12T12:00:00', color: '#777D74' },
    { title: 'Meeting', start: '2019-12-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2019-12-28T07:00:00', color: '#28a745' },
    { title: 'Meeting', start: '2020-01-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2020-01-02T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2020-01-25' },
    { title: 'Birthday Party', start: '2020-01-13T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2019-12-28' },
    { title: 'Meeting', start: '2020-01-12T14:30:00', color: '#089bab' },
    { title: 'Birthday Party', start: '2020-01-13T07:00:00', color: '#28a745' },
    { title: 'Click for Google', url: 'http://google.com/', start: '2020-01-28' },
    { title: 'All Day Event', start: '2020-02-01', color: '#fc9919' },
    { title: 'Long Event', start: '2020-02-07', end: '2020-02-10', color: '#ffc107' },
    { groupId: '999', title: 'Repeating Event', start: '2020-02-09T16:00:00', color: '#17a2b8' },
    { groupId: '999', title: 'Repeating Event', start: '2020-02-16T16:00:00', color: '#17a2b8' }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '10px' }}>
      <div className="col-md-4">
        <div className="iq-card calender-small">
          <div style={{ border:"none", borderRadius:"2px", padding:'10px'}}>
            <Calendar onChange={handleChange} value={date} />
          </div>
        </div>
        <div className="iq-card" style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="iq-card-body" style={{width:'50%'}}>
                <div className="iq-header-title">
                  <h4 className="card-title">Select Modality</h4>
                </div>
                <ul className="m-0 p-0 job-classification">
                {
                  data.map((item,index)=>(
                  <li className="" key={index}>
                    <input type='checkbox' value={inputValue} onChange={(e)=>{
                      setName(data[index].Name)
                    }}/>
                    {item.Name}{name}
                  </li>
                  ))
                }
                </ul>
              </div>
          <div className="iq-card" style={{ fontSize: '12px', width:'50%'}}>
            <div className="iq-card-body">
              <div className="iq-header-title">
                <h4 className="card-title" style={{fontSize:'20px'}}>Order</h4>
              </div>
              <ul className="m-0 p-0 today-schedule">
                {
                  order.map((value,index)=>(
                    <li className="d-flex">
                      <div className="schedule-text"style={{display:"flex", flexDirection:'row'}}>
                        <input type='radio'style={{accentColor: 'red'}}/>
                        <span style={{marginLeft:'10px'}}>{value.TestName}</span>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card" style={{ display: 'flex', flexDirection: 'column', marginLeft: '2%', width: '70%', padding: '10px' }}>
        <div style={{ fontSize: '8px', borderBottom: '2px solid #F5F5F5', paddingBottom:'10px' }}>
          <h1>Book Appointment</h1>
        </div>
        <div className="calendar-container" style={{ marginTop: '30px'}}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }}
            events={events}
            dateClick={handleDateClick}
          />
          <AppointmentModal
            show={showModal}
            handleClose={handleCloseModal}
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
}
