import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Vitals from '../Designs/Vitals';
import CheckList from '../Designs/CheckList';
import Portal from '../Portal';
import BookedPatient from '../Designs/BookedPatient';
import Header from '../Designs/Header';
import Footer from '../Designs/Footer';
import SideBar from '../Designs/SideBar';
import Dsfaghdjf from './FaceChart';

function RoomPatientList() {
    const [data, setData] = useState([]);
    const [showVitals, setShowVitals] = useState(false);
    const [showCheckList, setShowCheckList] = useState(false);
    const [list,setList]=useState(false);

    const handleVitals = () => {
        setShowVitals(!showVitals);
        console.log(showVitals)
    };

    const handleCheckList = () => {
        setShowCheckList(!showCheckList);
    };
    const handleChart=()=>{
        setList(!list)
    }
    return (
        <div className='wrapper'>
            <SideBar/>
            <div className='content-page' id='content-page'>
                <Header/>
                <div>
                <div className='list'>
                    <div className='search'>
                        <input type='text' placeholder='Search here' />
                        <i className="bi bi-search"></i>
                    </div>
                    <table className='PatientList' style={{color:'black'}}>
                        <thead>
                            <tr className='headers'>
                                <th>Patient Id</th>
                                <th>Patient Name</th>
                                <th>Order ID</th>
                                <th>Order</th>
                                <th>Appointment Date and Time</th>
                                <th>Nurse</th>
                                <th style={{ marginLeft: '10px' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {data.map((item, index) => (
                                <tr className='Values' key={index} >
                                    <td>{item.id}</td>
                                    <td onClick={handleChart}>{item.title}</td>
                                    <td>{item.category}</td>
                                    <td>{item.description}</td>
                                    <td>{item.price}</td>
                                    <td>{item.rating.rate}</td>
                                    <td>{item.rating.count}</td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <button style={{ color: 'rgb(84, 160, 215)', fontSize: '8px', border:'none', margin:'2px', background:'none'}} onClick={handleVitals}>Add Vitals</button>
                                            <button style={{ color: 'red', fontSize: '8px' , border:'none', background:'none'}} onClick={handleCheckList}>CheckList</button>
                                        </div>
                                    </td>
                                </tr>
                            ))} */}
                            <tr className='Values' >
                                    <td>01</td>
                                    <td><div className='container-fluid'>
                                            <div className='row'>
                                                <div style={{ background: 'none' }}>
                                                    <div>
                                                        {/* Button trigger modal */}
                                                        <button
                                                        style={{ color: 'rgb(84, 160, 215)', fontSize: '8px', margin: '2px', background: 'none', border: 'none', color:'black', fontSize:'14px' }}
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#bookpatient"
                                                        >
                                                        Alexandar
                                                        </button>
                                                        {/* Modal */}
                                                        <BookedPatient id="bookpatient"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></td>
                                    <td>AV01</td>
                                    <td>MRI</td>
                                    <td>01/06/2024 (10:00am)</td>
                                    <td>Marie</td>
                                    <td>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div style={{ background: 'none', display:'flex', flexDirection:'row',justifyContent:'center' }}>
                                                    <div>
                                                        {/* Button trigger modal */}
                                                        <button
                                                        style={{background:'none', border:'none',color:'blue'}}
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#vitalsModal"
                                                        >
                                                        <i class="bi bi-file-earmark-medical"></i>
                                                        </button>
                                                        {/* Modal */}
                                                        <Vitals id="vitalsModal"/>
                                                    </div>
                                                    <div>
                                                        {/* Button trigger modal */}
                                                        <button
                                                        style={{border:'none', background:'none',color:'red'}}
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#checkListModal"
                                                        >
                                                        <i class="bi bi-card-checklist"></i>
                                                        </button>
                                                        {/* Modal */}
                                                        <CheckList id="checkListModal"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                            </tr>
                        </tbody>
                    </table>
           {/* {
            showVitals && <Vitals onClose={handleVitals}/>
            } */}
            {
            showVitals && <Vitals onClose={handleVitals}/>
            }
            {
            showCheckList && <CheckList onClose={handleCheckList} />
            }
            {
            list && <BookedPatient onClose={handleChart}/>
            }
        </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default RoomPatientList;
//helo