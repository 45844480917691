import React from 'react'

export default function VitalList({id}) {
  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true" >
        <div className="modal-dialog">
            <div className="modal-content" style={{background:'none', border:'none'}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', backgroundColor:'white'}}>
                    <div style={{height:'700px', width:'1000px', backgroundColor:'white'}}>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', overflow:'scroll', height:'600px', scrollbarWidth:"none"}}>
                            <h1>Vitals Entries</h1>
                            <table>
                                <thead>
                                    <tr style={{border:'2px solid black'}}>
                                        <th>Blood Pressure</th>
                                        <th>Heart</th>
                                        <th>Respiration rate</th>
                                        <th>Date of test</th>
                                        <th>Body Temperature</th>
                                        <th>Blood Glucose</th>
                                        <th>Oxygen Saturation</th>
                                        <th>height</th>
                                        <th>Weight</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid black'}}>
                                        <td>120/80 mmHg</td>
                                        <td>60 bpm</td>
                                        <td>20 bpm</td>
                                        <td>12/12/2022  </td>
                                        <td>98.6(F)
                                            37(C)
                                        </td>
                                        <td>99(mg/dl)</td>
                                        <td>100%</td>
                                        <td>5 11(Ftin)
                                            71(in)
                                            180.3(cm)
                                        </td>
                                        <td>175(lvs)
                                            79.5(kg)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer" style={{marginTop:'20px', border:'none'}}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
