import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';

const SideBar = () => {

  const [loader,setLoader] = useState(true)

  useEffect(() => {
    setTimeout(()=>{
      setLoader(false)
    },500)
    
  }, [])
  return (
    <>
    
    {
      loader ? 
          <div id="loading">
            <LazyLoad height={762}>
                <div id="loading-center"></div>
            </LazyLoad>
          </div>
      : ""
    }
    


      {/* Sidebar */}
      <div className="iq-sidebar">
        <div className="iq-sidebar-logo d-flex justify-content-between">
          <a href="index.html">
            <img src="images/logo.png" className="img-fluid" alt="" />
            <span>XRay</span>
          </a>
          <div className="iq-menu-bt-sidebar">
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <div className="main-circle">
                  <i className="ri-more-fill" />
                </div>
                <div className="hover-circle">
                  <i className="ri-more-2-fill" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sidebar-scrollbar">
          <nav className="iq-sidebar-menu">
            <ul className="iq-menu" style={{ marginTop: '20%' }}>
              <li className="active">
                <Link to="/home" className="iq-waves-effect">
                  <i className="ri-hospital-fill" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li >
                <Link to="/patient" className="iq-waves-effect">
                  <i className="bi bi-person-bounding-box" />
                  <span>Patient</span>
                </Link>
              </li>
              <li>
                <Link to="/appointments" className="iq-waves-effect">
                  <i className="bi bi-calendar2-event" />
                  <span>Appointments</span>
                </Link>
              </li>
              <li>
                <Link to="/report" className="iq-waves-effect">
                  <i className="bi bi-clipboard-data-fill" />
                  <span>Report</span>
                </Link>
              </li>
              <li>
                <a className="iq-waves-effect">
                  <i className="ri-mail-open-fill" />
                  <span>Waiting Room</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </a>
                <ul className="iq-submenu">
                  <li>
                    <Link to="/rooma">
                      <i className="ri-inbox-fill" />
                      Room A
                    </Link>
                  </li>
                  <li>
                    <Link to="/roomb">
                      <i className="ri-edit-2-fill" />
                      Room B
                    </Link>
                  </li>
                  <li>
                    <Link to="/roomc">
                      <i className="ri-edit-2-fill" />
                      Room C
                    </Link>
                  </li>
                  <li>
                    <Link to="/roomd">
                      <i className="ri-edit-2-fill" />
                      Room D
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/doctor" className="iq-waves-effect">
                <i className="bi bi-person-lines-fill"/>
                  <span>Doctors</span>
                </Link>
              </li>
              <li>
                <Link to="/checkouts">
                  <i className="bi bi-person-check-fill" />
                  <span>CheckOut</span>
                </Link>
              </li>
              <li>
                <Link to="/accountsetting">
                  <i className="bi bi-gear" />
                  <span>Account Setting</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="bi bi-lock" />
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </nav>
          <div className="p-3" />
        </div>
      </div>
    </>
  );
};

export default SideBar;
