import React from 'react'


function Vitals({id}) {
    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className='complete' id={`${id}Label`}>
                <div className='vitals'>
                    <div className='heading'>
                        <p>Vitals- Alice Smith | CT Scan</p>
                    </div>
                    <div className='aboveForm'>
                        <div className='divison'>
                            <label>For:</label>
                            <input type='text'/>
                        </div>
                        <div className='divison' style={{marginBottom:'10px'}}>
                            <label>Registered Nurse:</label>
                            <input type='text'/>
                        </div>
                    </div>
                    <div className='heading'>
                        <p>Result Details</p>
                    </div>
                    <div className='Signinputs'>
                        <h5>Vital Sign input</h5>
                        <div style={{display:'flex', flexDirection:'row', marginLeft:'70%'}}>
                            <div class="toggle-container">
                                
                                <input type="checkbox" id="toggle" class="toggle-checkbox"/>
                                <label for="toggle" class="toggle-label"></label>
                            </div>
                            <div style={{fontSize:'8px', marginTop:'5px'}}>
                                <p style={{marginBottom:'0'}}>Standard</p>
                                <p style={{marginTop:'0'}}>Metric</p>
                            </div>
                        </div>
                        <div  className='forms'>
                            <table >
                                <tr>
                                    <td>Body Temperature</td>
                                    <td style={{display:"flex",flexDirection:'row', fontSize:"10px"}}>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                            <input type="radio" name="option" value="1"/>Celcius
                                            <input type="radio" name="option" value="2" style={{marginLeft:'10px'}}/>Farenhite
                                        </div>
                                        <input style={{width:'60px', marginLeft:'10px'}}type='text'/>
                                    </td>
                                </tr>
                                <tr >
                                    <td>Pulse rate</td>
                                    <td><input type='text'/></td>
                                </tr>
                                <tr>
                                    <td>Respiration Rate</td>
                                    <td><input type='text'/></td>
                                </tr>
                                <tr>
                                    <td>Blood Pressure(systolic)</td>
                                    <td><input type='text'/></td>
                                </tr>
                                <tr>
                                    <td>Blood Pressure(Diastolic)</td>
                                    <td><input type='text'/></td>
                                </tr>
                                <tr>
                                    <td>SPO2</td>
                                    <td><input type='text'/></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    {/* <div style={{display:'flex', flexDirection:'row', marginLeft:'60%'}}>
                        <button style={{backgroundColor:'rgb(192,192,192)'}} onClick={onClose}>Close</button>
                        <button style={{marginLeft:'20px'}}>Ok</button>
                    </div> */}
                </div>
            </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary">
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    </div>
      )
}

export default Vitals



