// import React from 'react';
// import DashCenter from '../DashCenter';
// import Bars from '../Designs/Bars';
// import Footer from '../Designs/Footer';
// export default function Home() {
//   return (
//     <div className='wrapper'
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         width: "100%",
//         height: "100vh",
//         overflow: 'scroll',
//         scrollbarWidth:'none'
//       }}
//     >
//         <Bars />
//       <div style={{display:'flex', flexDirection:'column',alignItems:'center',overflowy:'scroll', marginLeft:'18%', scrollbarWidth:'none', paddingTop:'8%', backgroundColor:'#F0F8FF', height:'fit-content', borderTopLeftRadius:'18px',borderBottomLeftRadius:'18px'}}>
//         <div style={{display:'flex'}}>
//             <DashCenter />
//         </div>
//         <Footer/>
//       </div>
//     </div>
//   );
// }
