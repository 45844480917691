import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideBar from '../Designs/SideBar'
import Header from '../Designs/Header'
import Footer from '../Designs/Footer'
import DashCenter from '../DashCenter'


export default function CheckOut() {
    const [data, setData] = useState([]);
    // useEffect(() => {
    //     axios.get('https://fakestoreapi.com/products')
    //         .then((response) => {
    //             setData(response.data);
    //         })
    //         .catch((err) => {
    //             console.log('Something went wrong:', err);
    //         });
    // }, []);

  return (
  <>
    <div className="wrapper">
      {/* Sidebar  */}
      <SideBar/>
      {/* Page Content  */}
      <div id="content-page" className="content-page">
        {/* TOP Nav Bar */}
        {/* TOP Nav Bar END */}
        <Header/>
        <div>
            <div className='list'>
                <div className='search'>
                    <input type='text' placeholder='Search here' />
                    <i className="bi bi-search"></i>
                </div>
                <table className='PatientList' style={{color:'black'}}>
                    <thead>
                        <tr className='headers'>
                            <th>Patient Id</th>
                            <th>Patient Name</th>
                            <th>Order ID</th>
                            <th>Order</th>
                            <th>Vital Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data.map((item, index) => (
                            <tr className='Values' key={index} >
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.category}</td>
                                <td>{item.description}</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                            </tr>
                        ))} */}
                        <tr className='Values'>
                                <td>a01</td>
                                <td>Xavier</td>
                                <td>US01</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a02</td>
                                <td>Andrew</td>
                                <td>US02</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a03</td>
                                <td>Thomas</td>
                                <td>US03</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a04</td>
                                <td>John</td>
                                <td>US04</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a05</td>
                                <td>Lyndsey</td>
                                <td>US05</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a06</td>
                                <td>Hennry</td>
                                <td>US06</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a07</td>
                                <td>Rohn</td>
                                <td>US07</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a08</td>
                                <td>Ben</td>
                                <td>US08</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a09</td>
                                <td>Edward</td>
                                <td>US09</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr><tr className='Values'>
                                <td>a10</td>
                                <td>Andrew</td>
                                <td>US10</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                        <tr className='Values'>
                                <td>a11</td>
                                <td>Andrew</td>
                                <td>US11</td>
                                <td>Ultrasound</td>
                                <td><button onClick={()=>console.log('Patient is checkedOut')} style={{border:'none', background:'none', color:'red'}}>CheckOut</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Footer/>
        {/* Footer */}

        {/* Footer END */}
      </div>
    </div>
  </>

  )
}
