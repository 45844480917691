import React, { useEffect, useState } from 'react'
import SideBar from '../Designs/SideBar'
import Header from '../Designs/Header'
import Footer from '../Designs/Footer'
import MyChart from './MyChart'
import VitalList from '../Designs/VitalList'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
// import LazyLoad from 'react-lazy-load'

export default function FaceChart({userData}) {

  const [diagnosis, setDiagnosis]=useState(false)
  const [surgery,setSurgery]=useState(false)
  const [allergy, setAllergy]=useState(false)
  const [medication, setMedication]=useState(false)
  const [chronic, setChronic]=useState(false)
  const [hist, setHist]=useState(false)
  const [data,setData]=useState([])
  

  useEffect(() => {
    axios.get('https://happ.tactionclients.com/api/getpatient')
        .then((response) => {
            setData(response.data.data);
            console.log(response.data.data);
        })
        .catch((err) => {
            console.log('Something went wrong:', err);
        });
}, []);
  return (
    <>
      <div className="wrapper">
        {/* Sidebar  */}
        <SideBar/>
        {/* Page Content  */}
        <div id="content-page" className="content-page">
          {/* TOP Nav Bar */}
          {/* TOP Nav Bar END */}
          <Header/>
          <div>
            <div className="container-fluid">
              <div className="row h-100" style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'row', border:'1px solid gray', margin:'10px'}}>
                  <img src='/images/cancerpatient.png' alt='something went wrong' style={{height:'120px', width:'100px', marginTop:'10px',borderRadius:'8px'}}/>
                  <div style={{marginTop:'10px', marginBottom:'10px'}}>
                    <div style={{display:'flex', flexDirection:'row', color:'green', fontSize:'15px',paddingLeft:'20px',marginLeft:'20px', borderBottom:'1px solid #DCDCDC'}}>
                      <p>Jenny Harris</p>
                      <p style={{marginLeft:'150px'}}>(Female | 24 years old | Feb,11,2000)</p>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', marginTop:'10px', marginLeft:'20px'}}>
                      <table>
                        <tr>
                        <td style={{fontFamily:'serif', color:'black'}}>Phone Number: </td>
                        <td>0987654321</td>
                        </tr>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>Email Id: </td>
                          <td>jenny@gmail.com</td>
                        </tr>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>DOB: </td>
                          <td>11/05/1996</td>
                        </tr>
                      </table>
                      <table style={{marginLeft:'10px'}}>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>Gender: </td>
                          <td>Female</td>
                        </tr>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>Address: </td>
                          <td>1001  N Rengstorff Av, Mountain View. CA 94040</td>
                        </tr>
                      </table>
                      <table style={{marginLeft:'10px'}}>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>Date Added: </td>
                          <td>June, 8 2021</td>
                        </tr>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>Last Scheduled Appointment: </td>
                          <td>Fri, july 02 2021</td>
                        </tr>
                        <tr>
                          <td style={{fontFamily:'serif', color:'black'}}>New Scheduled Appointment: </td>
                          <td>Mon, june 23 2022</td>
                        </tr>
                      </table>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', paddingTop:'20px',marginTop:'20px', borderTop:'1px solid #DCDCDC'}}>
                      <p style={{color:'green'}}>Referral Provider: James Smith</p>
                      <button style={{border:'none', borderRadius:'4px', width:'70px', marginLeft:'500px', marginTop:'20px'}}>Vitals <i className="bi bi-heart-pulse"></i></button>
                    </div>
                  </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', marginTop:'20px'}}>
                  <div style={{width:'45%', borderRight:'2px solid #DCDCDC', marginBottom:'20px'}}>
                    <div>
                      <h1 style={{fontSize:'20px', color:'green', border:'2px solid green', width:'max-content', padding:'10px', marginLeft:'50px',backgroundColor:'#90EE90', borderRadius:'8px'}}>Sufficient Patient Detail</h1>
                      <p style={{fontSize:'15px', marginTop:'20px', color:'black'}}>Patient vital details</p>
                      <div style={{display:'flex', flexWrap:'wrap'}}>
                        <p style={{marginLeft:'10px'}}><input type='radio' value='value1' name='option'/>Pulse rate</p>
                        <p style={{marginLeft:'10px'}}><input type='radio'  value='value2' name='option'/>Body Temperature</p>
                        <p style={{marginLeft:'10px'}}><input type='radio' value='value3' name='option'/>Bloode Pressure(Systolic)</p>
                        <p style={{marginLeft:'10px'}}><input type='radio' value='value4' name='option'/>Bloode Pressure(Diastolic)</p>
                        <p style={{marginLeft:'10px'}}><input type='radio' value='value5' name='option'/>respiration rate</p>
                        <p style={{marginLeft:'10px'}}><input type='radio'value='value6' name='option'/>SPO2</p>
                        <div>
                          <button style={{width:'max-content', marginLeft:'20px', border:'none', borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px', backgroundColor:'yellow'}}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#vitalist">View More Details</button>
                            <VitalList id="vitalist"/>
                            {/* <div  className="modal fade" id='vitalist' tabIndex="-1" aria-labelledby={`vitalist Label`} aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className='complete'>
                                    
                                  </div>
                                </div>
                              </div>
                            </div> */}
                        </div>
                      </div>
                        <MyChart/>
                    </div>
                  </div>
                  <div style={{width:'45%', marginLeft:'20px', marginBottom:'20px'}}>
                    <div>
                      <h1 style={{fontSize:'20px', color:'green', border:'2px solid green', width:'max-content', padding:'10px', marginLeft:'50px',backgroundColor:'#90EE90', borderRadius:'8px'}}>Past Medical Record</h1>
                      <p style={{marginTop:'20px', marginLeft:'10px', color:'black'}}>Medical History</p>
                      <table style={{width:'80%', padding:'20px'}}>
                        <tr style={{border:'1px solid black',borderBottom:'none'}}>
                          <td style={{paddingLeft:"10px"}}>
                              <p onClick={()=>setDiagnosis(!diagnosis)}>Previous Diagnosis</p>
                          {
                            (diagnosis==true)&&
                            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                            <p>Diagnosis Name<input style={{border:'none', borderBottom:'1px solid black', background:'none'}}type='text'/></p>
                            <p>Date of Diagnosis<input style={{border:'none', borderBottom:'1px solid black', background:'none'}}type='text'/></p>
                            <p>Physician<input style={{border:'none', borderBottom:'1px solid black', background:'none'}}type='text'/></p>
                            <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none'}}type='text'/></p>
                        </div>
                          }
                          </td>
                          </tr>
                          <tr style={{border:'1px solid black',borderBottom:'none'}}>
                            <td style={{paddingLeft:"10px"}}>
                              <p onClick={()=>setSurgery(!surgery)}>Previous Surgery</p>
                              {(surgery==true)&&<div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                                  <p>Surgery Name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                  <p>Date of Surgery<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                  <p>Doctor<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                  <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                              </div>}
                            </td>
                          </tr>
                          <tr style={{border:'1px solid black',borderBottom:'none'}}>
                            <td style={{paddingLeft:"10px"}}>
                              <p onClick={()=>setAllergy(!allergy)}>Allergies</p>
                              {
                                (allergy==true)&&<div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                                <p>Allergy Name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Duration of allergy<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Prescribed Doctor<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                            </div>
                              }
                            </td> 
                          </tr>
                          <tr style={{border:'1px solid black',borderBottom:'none'}}>
                          <td style={{paddingLeft:"10px"}}>
                            <p onClick={()=>setMedication(!medication)}>Medications</p>
                            {(medication==true)&&
                              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                                <p>Allergy Name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Duration of allergy<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Prescribed Doctor<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                              </div>
                            }
                          </td>
                        </tr>
                        <tr style={{border:'1px solid black',borderBottom:'none'}}>
                          <td style={{paddingLeft:"10px"}}>
                            <p onClick={()=>setChronic(!chronic)}>Choronic Conditions</p>{
                              (chronic==true)&&
                              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                                <p>Allergy Name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Duration of allergy<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Prescribed Doctor<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                            </div>
                            }
                          </td>
                        </tr>
                        <tr style={{border:'1px solid black'}}>
                          <td style={{paddingLeft:"10px"}}>
                            <p onClick={()=>setHist(!hist)}>Radiology History</p>
                            {
                              (hist==true)&&
                              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'10px', marginRight:'50px'}}>
                                <p>Test name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Last Visit<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Radiologist Name<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                                <p>Notes<input style={{border:'none', borderBottom:'1px solid black', background:'none', marginLeft:'10px'}}type='text'/></p>
                              </div>
                            }</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
          {/* Footer */}

          {/* Footer END */}
        </div>
      </div>
  </>
  )
}
